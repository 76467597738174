export const underscoredToCamelCase = function toCamelCase(text) {
    return text.replace(/\_([a-z])/g, function (g) {
        return g[1].toUpperCase();
    })
}

export const fillVueObject = (vueObject) => {
    document.querySelectorAll('[data-v-fill]').forEach((element, index) => {
        let key = underscoredToCamelCase(extractName(element.getAttribute('name')));
        let value = element.dataset['vFill'];
        vueObject.$set(vueObject.data, key, value);
    });
    document.querySelectorAll('[data-v-json]').forEach((element, index) => {
        let key = element.dataset['vField'];
        let value = JSON.parse(element.dataset['vJson']);
        vueObject.$set(vueObject.data, key, value);
    });
}

export const fillDataObject = (data) => {
    document.querySelectorAll('[data-v-fill]').forEach((element, index) => {
        let key = underscoredToCamelCase(extractName(element.getAttribute('name')));
        data[key] = element.dataset['vFill'];
    });
    document.querySelectorAll('[data-v-json]').forEach((element, index) => {
        let key = element.dataset['vField'];
        data[key] = JSON.parse(element.dataset['vJson']);
    });
    document.querySelectorAll('[data-v-bool]').forEach((element, index) => {
        let key = underscoredToCamelCase(extractName(element.getAttribute('name')));
        data[key] = JSON.parse(element.dataset['vBool']);
    });
    return data;
}

export const extractName = (text) => {
    return text.replace(/.*\[/g, "").replace("]", "")
}